// extracted by mini-css-extract-plugin
export var activeSec = "index-module--active-sec--hevU-";
export var animShowletters = "index-module--anim-showletters--GYN9A";
export var bigDivDark = "index-module--big-div-dark--GtphF";
export var bigDivSolid = "index-module--big-div-solid--ns9BP";
export var bigDivTop = "index-module--big-div-top--l0RSL";
export var bigDivTrans = "index-module--big-div-trans--uhUZo";
export var bigDivWhite = "index-module--big-div-white--oOVbg";
export var chatBotFrame = "index-module--chat-bot-frame--ohYtU";
export var container = "index-module--container--2GuoF";
export var layerTop = "index-module--layer-top--j6NHU";
export var moreButton = "index-module--more-button--b8cPz";
export var secHead = "index-module--sec-head--fpk8Y";
export var showletters = "index-module--showletters--zFqJu";
export var showlettersMobile = "index-module--showlettersMobile--2WgD7";
export var splashStyle1 = "index-module--splash-style1--Mua9J";
export var tool = "index-module--tool--83RSG";